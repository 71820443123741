import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Chad's Glass`}</h1>
    <p>{`This site reaches out to instagram for the first 20 or so posts and displays them in a grid. You can pull up the modal by clicking on one of the thumbnails. Videos have a video icon in the top right corner.`}</p>
    <p>{`As you scroll down the page you will see a loading indicator, once you see this, the page reaches out to instagram and loads the next 20 until there are no more posts from him.`}</p>
    <p>{`I decided to use `}<a href="https://github.com/hyperapp/hyperapp" target="_blank">{`hyperapp js`}</a>{` library which I was drawn to after looking through the github repo. It has a simple state, actions, and view management which allowed for a very smooth development.`}</p>
    {
      /* Check it out <a href="http://chads-glass.czaas.com" target="_blank">chads-glass.czaas.com</a>. */
    }
    <img src="https://firebasestorage.googleapis.com/v0/b/my-site-73c47.appspot.com/o/z707ASIVyfReSRscecCb3y3JB5J3%2Fimages%2Fchads-glass-site.jpg?alt=media&token=5309d05a-b411-4a35-9eee-be2df8e2544d" width="900" height="474" alt="Image of modal on Chads Glass" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      